<template>
  <main>
    <div class="alert-crespon" v-if="crespon" tag="prod">
      <div class="container">
        <img src="/img/crespon.png" alt="Crespón luto" />
        <p class="title">
          En señal de luto por las víctimas de las lluvias e inundaciones en el
          sureste español
        </p>
      </div>
    </div>
    <carrousel-home />
    <strap-home />
    <present-home />
    <section class="destc-home">
      <div class="container-grid container">
        <div class="grid-small">
          <p class="title-dest">Sala de exposiciones</p>
          <template
            v-for="(exposition, index) in Object.values(
              store.expositions
            ).slice(0, 1)"
            :key="index"
          >
            <expo-room :dataContent="exposition"></expo-room>
          </template>
        </div>
        <div class="grid-big">
          <div class="grid-2">
            <section class="grid" v-if="store.videos.items[this.yearVideos]">
              <p class="title-dest container p-left-10-px">Imago CERMI</p>
              <template
                v-for="(media, index) in Object.values(
                  store.videos.items[this.yearVideos][this.page]
                ).slice(0, 1)"
                :key="index"
              >
                <newVerticalImg
                  :dataContent="media"
                  typeContent="media"
                  class="w-100"
                ></newVerticalImg>
              </template>
              <div class="justify-content-center d-flex w-100">
                <router-link
                  :to="{
                    name: 'multimediaImago',
                    params: {
                      Nyear: yearVideos,
                    },
                  }"
                  class="btn-white"
                  title="Ir a Ver más entradas"
                  alt="Ir a Ver más entradas"
                  >Ver más</router-link
                >
              </div>
            </section>
            <section class="grid" v-if="store.images.items[this.yearImages]">
              <p class="title-dest container p-left-10-px">
                Galería de imágenes
              </p>
              <template
                v-for="(img, index) in Object.values(
                  store.images.items[this.yearImages][this.page]
                ).slice(0, 1)"
                :key="index"
              >
                <newVerticalImg
                  :dataContent="img"
                  typeContent="media"
                  class="w-100"
                ></newVerticalImg>
              </template>
              <div class="justify-content-center d-flex w-100">
                <router-link
                  :to="{
                    name: 'multimediaImagenes',
                    params: {
                      Nyear: yearImages,
                    },
                  }"
                  class="btn-white"
                  title="Ir a Ver más imágenes"
                  alt="Ir a Ver más imágenes"
                  >Ver más</router-link
                >
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>

    <links-interest />
    <carrousel-our-job />
    <carrousel-about-us />
  </main>
</template>

<script>
import CarrouselHome from "@/components/Home/CarrouselHome";
import StrapHome from "@/components/Home/StrapHome";
import PresentHome from "@/components/Home/PresentHome";
import ExpoRoom from "@/components/Home/ExpoRoom";
import CarrouselOurJob from "@/components/Home/CarrouselOurJob";
import CarrouselAboutUs from "@/components/Home/CarrouselAboutUs";
import LinksInterest from "@/components/Home/LinksInterest";
import { contentStore } from "@/stores/contents";
import newVerticalImg from "@/components/Card/NewVerticalImg";
export default {
  name: "HomeView",
  setup() {
    const store = contentStore();
    store.loadExpositions();
    store.loadRoutes();

    return {
      store,
    };
  },
  components: {
    CarrouselAboutUs,
    CarrouselOurJob,
    LinksInterest,
    ExpoRoom,
    StrapHome,
    CarrouselHome,
    PresentHome,
    newVerticalImg,
  },
  data() {
    return {
      page: 1,
      year: new Date().getFullYear(),
      crespon:true,
    };
  },
  mounted() {
    this.store.loadVideos({ maxYear: new Date().getFullYear() });
    this.store.loadImages({ maxYear: new Date().getFullYear() });
    const fechaActual = new Date().getTime();
    const fechaFin = new Date(2024, 10, 3).getTime();
    if (fechaActual >= fechaFin) {
      this.crespon = false
    }
  },
  computed: {
    yearVideos() {
      let y;
      if (this.store.videos.items[new Date().getFullYear()] === undefined) {
        y = 2022;
        this.store.loadVideos({ maxYear: y });
      } else {
        y = new Date().getFullYear();
        this.store.loadVideos({ maxYear: y });
      }

      return y;
    },
    yearImages() {
      let y;

      if (this.store.images.items[new Date().getFullYear()] === undefined) {
        y = 2022;
        this.store.loadImages({ maxYear: y });
      } else {
        y = new Date().getFullYear();
        this.store.loadImages({ maxYear: y });
      }
      return y;
    },
    loading() {
      return this.store.loading;
    },
  },
  watch: {
    loading() {
      if (this.loading) {
        let loader = this.$loading.show({
          container: this.$refs.loadingContainer,
          zIndex: 99,
        });
        setTimeout(() => {
          loader.hide();
        }, 1000);
      }
    },
  },
};
</script>
