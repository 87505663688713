<template>
  <main>
    <div class="d-flex w-100 strap list-style-none align-items-center ">
      <div class="strap__item"
           v-for="(route, key) in Object.values(store.routesTop).slice(0,6)"
           :key="key">
        <a :href="route.url"
           v-if="!route.url.includes('https://cermi.es/')"
           :title="'Ir a '+route.url"
           class=""
           :target="[route.blank == 1 ? '_blank' : '_self']">
         
          <img class="img-carrousel" v-if="route.image.url" :src="route.image.url" :alt="route.name">
        </a>
        <router-link :to="route.url.replaceAll('https://cermi.es/', '')"
                     v-else
                     :title="'Ir a '+route.url"
                     class=""
                     :target="[route.blank == 1 ? '_blank' : '_self']">
          <!-- <p class="title">{{route.name}} </p> -->
          <img class="img-carrousel" v-if="route.image.url" :src="route.image.url" :alt="route.name">
        </router-link>
      </div>

    </div>
  </main>
</template>

<script>
import {contentStore} from '@/stores/contents'

export default {
  name: "StrapHome",
  setup() {
    const store = contentStore();
    store.loadRoutes()
    return {
      store
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../styles/colours.scss";

.strap {
  background-color: white;
  display: flex;
  flex-wrap: wrap;

  .strap__item {
    transition: .1s;
    display: block;
    height: 212px;
    width: calc(100% / 6);
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    overflow: hidden;
    flex-grow:1;
    /* text-align: center; */
    /* align-items: center; */

    img {
      width: 100%;
      margin: 0 auto;
      object-fit: cover;
      object-position: center center;
      height: auto;
    }

    .title {
      color: white;
    }

    .description {
      margin-bottom: 15px;
      color: $black;
    }
  }

  .strap__item:hover {
    cursor: pointer;
    scale: 1.1;
    transition: .2s;
    border-radius: 15px;
    z-index: 3;
  }

}
</style>
