<template lang="html">

  <section class="present-home container">
    <section class="m-top-20-px">
      <h2 class="title-grand title__double-center margin-auto" data-text="Actualidad">Actualidad</h2>
    </section>
    <section class="container-grid">
      <div class="grid-big">
        <p class="title-dest" v-if="store.getEntries(this.page)">Noticias</p>
        <section class="grid-2" v-if="store.getEntries(this.page)">
          <template v-for="(notice, index) in  Object.values(store.getEntries(this.page)).slice(0,2)" :key="index">
            <newVerticalImg :dataContent="notice" dataUrl="noticias" typeContent="entry"></newVerticalImg>
          </template>
          <div class="justify-content-center d-flex w-100">
            <router-link to="/hemeroteca" class="btn-white" title="Ir a página de Hemeroteca" alt="Ir a página de Hemeroteca">Ver más</router-link>
          </div>
        </section>

        <section v-if="store.getNovelties( this.page)">
          <p class="title-dest m-top-40-px">Novedades</p>
          <template v-for="(novelti, index) in Object.values(store.getNovelties( this.page)).slice(0,4)" :key="index">
            <noveltie :dataContent="novelti"></noveltie>
          </template>
          <div class="justify-content-center d-flex w-100">
            <router-link :to="{name:'novedades'}" title="Ir a Ver más novedades" alt="Ir a Ver más novedades" class="btn-white">Más novedades</router-link>
          </div>
        </section>
      </div>
      <div class="grid-small">
        <template v-if="store.getEvents(this.year, this.page)">
          <p class="title-dest">Agenda</p>
          <template v-for="(event, index) in Object.values(store.getEvents(this.year, this.page)).slice(0,1)" :key="index">
            <eventViewSimple :dataContent="event" typeContent="event"></eventViewSimple>
          </template>
          <div class="column-center m-bottom-30-px">
            <router-link :to="{name:'events'}" class="btn-white m-bottom-30-px" title="Ver agenda">Ver Agenda</router-link>
          </div>
        </template>
        <template v-if="store.documents">
          <p class="title-dest">Colecciones</p>
          <div class="container-colecc">
            <card-publication class="m-top-1-rem item" v-for="(document, index) in Object.values(store.documents).slice(0,2)" :key="index" :title="document.name" :subtitle="document.description" :img="document.image.url" :url="document.file[0].url">
              <template v-slot:body>
                <p class="date-text">{{currentDateTime(document.publish_date)}}</p>
              </template>
            </card-publication>
          </div>
          <div class="justify-content-center d-flex w-100">
            <router-link v-for="(collection, key) in Object.values(store.collections).slice(0,1)" :key="key" :value="key" :to="{
                      name: 'colecciones',
                      params: {
                        id: collection.slug
                      }
                    }" title="Ir a página de Colecciones" alt="Ir a página de Colecciones" class="btn-white">Ver más
            </router-link>
          </div>
        </template>

      </div>
    </section>
  </section>

</template>

<script lang="js">
  import moment from 'moment'
  import newVerticalImg from "@/components/Card/NewVerticalImg";
  import eventViewSimple from "@/components/Card/EventViewSimple";
  import noveltie from "@/components/Card/Noveltie";
  import CardPublication from "@/components/Card/CardPublication";
  import { contentStore } from '@/stores/contents'
  export default {
    name: 'present-home',
    props: [],
    components: {
      newVerticalImg,
      eventViewSimple,
      CardPublication,
      noveltie
    },
    setup() {
      const store = contentStore();
      store.loadNews()
      store.loadVideos()
      store.loadDocuments()
      store.loadBanners()
      store.loadEvents(
        {
          dateCurrent: new Date()
        }
      )
      return {
        store
      }
    },
    mounted() {
      this.store.loadNovelties(
        {
          maxYear: new Date().getFullYear(),
          page: this.page,

        }
      )

    },
    data() {
      return {
        page: 1,
        year: new Date().getFullYear()
      }
    },
    methods: {
      currentDateTime(date) {
        return moment(date).locale('es').format('L')
      },

    },
    computed: {
      yearNovelties() {
        let y;

        if (this.store.novelties.items[new Date().getFullYear()] === undefined) {
          y = 2022
          this.store.loadNovelties({ maxYear: y })

        } else {
          y = new Date().getFullYear()
          this.store.loadNovelties({ maxYear: y })

        }
        return y
      },
    }
  }


</script>

<style scoped lang="scss">
  @import "@/styles/mixins.scss";

  .present-home {
    .container-grid {
      padding-top: 0;
    }
  }

  .title-grand {
    @include sm {
      font-size: 4rem !important;
    }
  }

  .grid-big,
  .grid-small {
    @include sm {
      width: 100vw;
      padding: 1rem;

      .grid-2 {
        .grid {
          width: 100%;

          img {
            display: block;
            width: 100%;
          }
        }
      }
    }
  }
</style>