<template lang="html">

  <section class="carrousel-about-us container">
    <section class="section__title ">
      <p class="text-big">Qué es el CERMI</p>
      <p class="text-small">Qué es el CERMI</p>
    </section>
    <p class="subtitle text-center color-primary">El Comité Español de Representantes de Personas con Discapacidad, más conocido por sus siglas, CERMI, es la plataforma de representación, defensa y acción de la ciudadanía española con discapacidad.</p>
    <carrousel-2 :dataContent="$tm('translations.carrouselData.cermi')" />
  </section>


</template>

<script lang="js">
  import Carrousel2 from "@/components/Global/Carrousel2";
  export default {
    name: 'carrousel-about-us',
    props: [],
    components: {
      Carrousel2
    },
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
  }


</script>

<style scoped lang="scss">
  .carrousel-about-us {}
</style>