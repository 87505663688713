<template>
  <main class="position-relative d-block">
    <section class="section__links-interest container">
      <section class="section__title  ">
        <p class="text-big">Enlaces de interés</p>
      </section>
      <agile :options="myOptions" ref="carousel" class="carrousel-links" v-if="store.routesSlider && Object.values(store.routesSlider).length != 0">
        <a :href="route.url" :title="'Ir a '+route.url" class="strap__item" v-for="(route, key) in store.routesSlider" :key="key" :target="[route.blank == 1 ? '_blank' : '_self']">
          <div class="cont-title">
            <p class="title color-white">{{route.name}} </p>
          </div>
          <img class="img-carrousel" v-if="route.image.url" :src="route.image.url" alt="Imagen carrousel">
        </a>
      </agile>
      <other-links-interest class="links-interest-box" />
    </section>
  </main>
</template>
<script>
  import {
    VueAgile
  } from "vue-agile";
  import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
  import { contentStore } from '@/stores/contents'
  export default {
    name: "LinksInterest",
    components: {
      agile: VueAgile,
      OtherLinksInterest
    },
    setup() {
      const store = contentStore();
      store.loadRoutes()
      return {
        store
      }
    },
    data() {
      return {
        myOptions: {
          responsive: [{
            breakpoint: 900,
            settings: {
              autoplay: false,
              fade: false,
              navButtons: true,
              slidesToShow: 4,
              dots: false,
              speed: 2000,
              unagile: false,
            },
          }, {
            breakpoint: 0,
            settings: {
              autoplay: false,
              fade: false,
              navButtons: true,
              slidesToShow: 1,
              dots: false,
              speed: 2000,
              unagile: false,
            },
          }],

        },
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../styles/colours.scss";


</style>