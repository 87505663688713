<template lang="html">

  <section class="carrousel-our-job container">
    <p class="title-grand-primary text-center">Iniciativas</p>
   
    <carrousel-2 :dataContent="$tm('translations.carrouselData.iniciativas')" />
    
  </section>

</template>

<script lang="js">
  import Carrousel2 from "@/components/Global/Carrousel2";

  export default {
    name: 'carrousel-our-job',
    props: [],
    components: {
      Carrousel2
    },
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
  }


</script>

<style scoped lang="scss">
  .carrousel-our-job {
    .carrousel-2 {
      margin-bottom: 60px;
      padding-top: 0;
    }
  }
</style>