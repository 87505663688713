<template>
  <section class="background-home d-block">
    <agile :options="myOptions" ref="carousel" class="carrousel-home" v-if="store.banners && Object.values(store.banners).length != 0">
      <div v-for="(banner, key) in store.banners" :key="key">
        <a :href="banner.url" :title="'Ir a '+banner.url" class="container-grid text-decoration-none container" :target="[banner.blank == 1 ? '_blank' : '_self']">
          <div class="grid-big p-2-rem">
            <h2 class="subtitle">{{banner.name}}</h2>
            <article class="font-family-frank-ruhl text-black text-bold">{{banner.description}}
            </article>
          </div>
          <div class="grid-small">
            <img class="img-carrousel d-block margin-auto w-100 p-10-px" v-if="banner.image.url" :src="banner.image.url" alt="Imagen ruta destacada">
          </div>
        </a>
      </div>

    </agile>
  </section>
</template>

<script>
  import {
    VueAgile
  } from "vue-agile";
  import { contentStore } from '@/stores/contents'
  export default {
    name: "CarrouselHome",
    components: {
      agile: VueAgile
    },
    setup() {
      const store = contentStore();
      store.loadBanners()
      return {
        store
      }
    },
    data() {
      return {
        myOptions: {
          responsive: [{
            breakpoint: 0,
            settings: {
              autoplay: false,
              fade: true,
              navButtons: false,
              slidesToShow: 1,
              dots: true,
              speed: 2000
            },
          }],
        },
      }
    }
  }
</script>

<style scoped lang="scss">
  @media only screen and (min-width: 360px) and (max-width: 768px) {

    .background-home {
      background: url("/img/carrousel/background-img.svg") no-repeat;
      background-size: cover;
    }

    h2 {
      font-size: 30px !important;
    }

    article {
      font-size: 26px;
    }

    .container-grid {
      display: flex;
      flex-direction: column;

      .grid-small,
      .grid-big {
        display: block;
        width: 100%;
        padding: 20px 0;
      }
    }
  }

  @media only screen and (min-width: 1200px) {

    .background-home {
      background: url("/img/carrousel/background-img.svg") no-repeat;
      background-size: cover;
      padding: 50px;
    }

    h2 {
      font-size: 40px !important;
    }

    article {
      font-size: 34px;
    }

    .container-grid {
      padding: 0;

      .grid-big {
        padding-right: 50px;
      }
    }
  }
</style>