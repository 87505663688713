<template>
  <section>
    <a :href="url" target="_blank" title="Descargar publicación" alt="Descargar publicación" class="d-flex card-publication">
      <img :src="img" alt="imagen descargar" />
      <section class="content">

        <p class="title">{{subtitle}}</p>
        <slot name="body"></slot>
      </section>
    </a>
  </section>
</template>

<script>
  export default {
    name: "CardPublication",
    props: ['title', 'subtitle', 'img','url']
  }
</script>

<style scoped lang="scss">
  @import "../../styles/general";

  .card-publication {
    color           : black;
    text-decoration : none;
    margin-bottom   : 20px;
    img {
      width           : 33%;
      border-radius   : 13px;
      height          : 220px;
      object-fit      : cover;
      object-position : center;
    }

    .content {
      width        : 65%;
      padding-left : 20px;
    }
  }
</style>
